<template>
    <div class="layout-task">
        <div class="layout-task-menu">
            <div class="menu-system">
                <div class="menu-top">
                    <h1 class="title" :class="taskInspectionTypeId === ''?'active':''" @click="onLinks('')">作业任务分类</h1>
                </div>
                <!--                <div class="menu-input">-->
                <!--                    <Input suffix="ios-search" placeholder="请输入" class="input"/>-->
                <!--                </div>-->
            </div>
            <div class="menu-list">
                <div class="list-item" :key="'child1'+key" v-for="(deviceList,key) in deviceListArray">
                    <div class="item-thead">
                        <a href="javascript:">
                            <Icon :type="deviceList.visible?'md-arrow-dropdown':'md-arrow-dropright'" class="icon"  @click="deviceList.visible = !deviceList.visible"/>
                            <p :class="taskInspectionTypeId ===  deviceList.value?'active':''" @click="onLinks(deviceList.value)">{{ deviceList.label }}</p>
                        </a>
                    </div>
                    <div class="item-body" v-if="deviceList.visible">
                        <div class="item-child" :key="'child2'+key" v-for="(children,key) in deviceList.children">
                            <div class="child-thead" :style="children.children.length === 0?'padding-left:20px':''">
                                <a href="javascript:" class="href">
                                    <Icon v-if="children.children.length !== 0"  @click="children.visible = !children.visible" :type="children.visible?'md-arrow-dropdown':'md-arrow-dropright'" class="icon" />
                                    <p :class="taskInspectionTypeId === children.value?'active':''" @click="onLinks(children.value)">{{ children.label }}</p>
                                </a>
                            </div>
                            <div class="child-body" v-if="children.visible">
                                <ul>
                                    <li :key="'child3'+key" v-for="(child,key) in children.children">
                                        <a href="javascript:" class="href" :class="taskInspectionTypeId === child.value?'active':''" @click="onLinks(child.value)"><Icon type="ios-copy-outline" class="icon"/>{{ child.label }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-task-list">
            <!--s: Search 区域-->
            <div class="device-list-search">
                <h1 class="title"><i class="icon"></i>作业任务</h1>
                <div class="device-list-select">
                    <div class="box">
                        <div class="box-list">
                            <span class="label">任务名称：</span>
                            <Input v-model="taskListParams.tasksName" clearable placeholder="任务名称" style="width: 120px" />
                        </div>
                        <div class="box-list">
                            <span class="label">任务状态：</span>
                            <Select v-model="taskListParams.status" clearable style="width: 120px">
                                <Option v-for="(status,key) in taskStatus" :value="status.value" :key="key">{{ status.label }}</Option>
                            </Select>
                        </div>
                        <div class="box-list">
                            <span class="label">引用流程：</span>
                            <Input v-model="taskListParams.flowName" clearable placeholder="引用流程" style="width: 120px" />
                        </div>
                    </div>
                    <div class="box">
                        <Button @click="onReset">重置</Button>
                        <Button type="primary" @click="onSearch">查询</Button>
                    </div>
                </div>
            </div>
            <!--e: Search 区域-->
            <!--s: Data 区域-->
            <div class="device-list">
                <Table :loading="taskLoading" :height="deviceListHeight" stripe="stripe" :columns="taskListColumns" :data="taskListArray.content" >
                    <template slot="任务名称" slot-scope="{ row }">
                        <Poptip placement="top-start" trigger="hover" :content="row.tasksName">
                            <span class="task-name">{{ row.tasksName }}</span>
                        </Poptip>
                    </template>
                    <template slot="引用流程" slot-scope="{ row }">
                        <Poptip placement="top-start" trigger="hover" :content="row.flowName">
                            <a href="javascript:" class="task-flow" @click="onFlowConfig(row)">{{ row.flowName }}</a>
                        </Poptip>
                    </template>
                    <template slot="发布人" slot-scope="{ row }">
                        <span class="create-name">{{ row.createName }}</span>
                    </template>
                    <template slot="审批人" slot-scope="{ row }">
                        <Poptip placement="bottom-start">
                            <Button class="user-item" v-if="row.approvalUsers.length!==0">{{ row.approvalUsers.length === 1?row.approvalUsers[0].userName:row.approvalUsers[0].userName+'...' }}</Button>
                            <template #content v-if="row.approvalUsers.length!==0">
                                <h1 class="approve-title">依此审批 <span class="label" v-if="row.approvalUsers.filter(item=>item.state === 1).length === row.approvalUsers.length">已通过</span></h1>
                                <div class="approval-box">
                                    <div class="approve-steps-item" :key="key" v-for="(approval,key) in row.approvalUsers">
                                        <div class="steps-tail" :class="approval.state === 0?'green':approval.state === 2?'red':''" v-if="key!==row.approvalUsers.length-1"></div>
                                        <div class="steps-head" :class="approval.state === 0?'green':approval.state === 2?'red':''"><Icon :type="approval.state === 0?'ios-radio-button-off':approval.state === 1?'ios-checkmark-circle':'ios-close-circle'" size="20"/></div>
                                        <div class="steps-main">
                                            <div class="steps-title">{{ key+1 }}级审批人{{ approval.state === 1?'/已同意':approval.state === 2?'/已驳回':'' }}</div>
                                            <div class="steps-content">
                                                <a href="javascript:" class="steps-add"  v-if="approval.id === ''"><Icon type="md-add" size="20"/></a>
                                                <div class="steps-name-box" v-if="approval.id !== ''">
                                                    <span class="avatar" :style="'background-image:url('+(approval.profilePicture === ''?defaultAvatar:approval.profilePicture)+')'"></span>
                                                    <div class="text">
                                                        <span class="name">{{ approval.userName }}</span>
                                                        <span class="tel">{{ approval.mobile }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </Poptip>
                        {{ row.approvalUsers.length === 0?'/':'' }}
                    </template>
                    <template slot="状态" slot-scope="{ row }">
                        <font v-if="row.status === 0" class="black">未发布</font>
                        <font v-if="row.status === 1" class="blue">可执行</font>
                        <font v-if="row.status === 2" class="red">待审批</font>
                        <font v-if="row.status === 3" class="yellow">进行中</font>
                        <font v-if="row.status === 4" class="black">已退回</font>
                        <font v-if="row.status === 5" class="grey">已关闭</font>
                    </template>
                    <template slot="执行人" slot-scope="{ row }">
                        <Poptip placement="bottom-start">
                            <Button class="user-item" v-if="row.executorUsers.length!==0">{{ row.executorUsers.length === 1?row.executorUsers[0].userName:row.executorUsers[0].userName+'...' }}</Button>
                            <template #content v-if="row.executorUsers.length!==0">
                                <div class="task-box">
                                    <div class="user-item-box" :key="key" v-for="(userList,key) in row.executorUsers">
                                        <span class="avatar" :style="'background-image:url('+(userList.profilePicture === ''?defaultAvatar:userList.profilePicture)+')'"></span>
                                        <div class="box-r">
                                            <span class="name">{{ userList.userName }}</span>
                                            <span class="tel">{{ userList.mobile }}</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </Poptip>
                        {{ row.executorUsers.length === 0?'/':'' }}
                    </template>
                    <template slot="创建时间" slot-scope="{ row }">
                        {{ row.createTime?row.createTime.replace(/:..$/, '') : '/'}}
                    </template>
                    <template slot="操作" slot-scope="{ row }">
                        <div class="handle">
                            <a href="javascript:" @click="onQueryLink(row)">作业记录</a>
                            <a href="javascript:" v-if="row.status === 1" @click="onCode(row)">二维码</a>
                        </div>
                    </template>
                </Table>
                <div class="device-list-page" v-if="taskListArray.content && taskListArray.content.length !== 0">
                    <Page :total="taskListArray.totalSize" :current="taskListArray.currentPage" size="small" show-total show-sizer :page-size="taskListParams.displayNumber" @on-change="onSearchPage" @on-page-size-change="onSearchPageSize"/>
                </div>
            </div>
            <!--e: Data 区域-->
            <!--s: Code modal-->
            <Modal
                v-model="qrCodeVisible"
                title="二维码"
                :width="600"
                class-name="task-modal"
                footer-hide>
                <div class="task-code">
                    <div class="box">
                        <div class="code" id="qrcode" ref="taskInspectionRef">
                            <vue-qr :logoSrc="logoSrc" :text="QRCode.toString()" :size="500" class="layout-qr-code"></vue-qr>
                            <span class="name">{{ tasksName }}</span>
                        </div>
                        <div class="down-load">
                            <a href="javascript:" @click="onDowns"><Icon custom="image upload" />下载任务二维码</a>
                        </div>
                    </div>
                </div>
            </Modal>
            <!--e: Code modal-->
        </div>
    </div>
</template>
<script>
import TaskList from './list'
export default TaskList
</script>
<style lang="less">
@import "list";
</style>
